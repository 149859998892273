import { useLingui } from '@lingui/react'
import Button, { ButtonType } from 'components/Core/Button/Button'
import Field from 'components/Core/Field/Field'
import Section from 'components/Core/Field/Section'
import HorizontalRule from 'components/Core/HorizontalRule/HorizontalRule'
import { IconType } from 'components/Core/Icon/IconType'
import Column from 'components/Core/Layout/Column/Column'
import Row from 'components/Core/Layout/Row/Row'
import Paragraph from 'components/Core/Typography/Paragraph'
import DetailsInformationFieldset from 'components/fieldsets/participants/learningNeeds/fieldsets/DetailsInformationFieldset'
import OfferInformationFieldset from 'components/fieldsets/participants/learningNeeds/fieldsets/OfferInformationFieldset'
import ReferenceCardLinkedHeader from 'components/Participants/cards/ReferenceCard/Headers/ReferenceCardLinkedHeader'
import { OngoingStatus } from 'components/Participants/cards/ReferenceCard/Headers/Status/OngoingStatus'
import { ReferenceCard } from 'components/Participants/cards/ReferenceCard/ReferenceCard'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import {
    LearningNeedQuery,
    Maybe,
    OrganizationTypeEnum,
    ParticipationProviderOption,
    ParticipationsQuery,
    ParticipationStatus,
} from 'graphql/v2/generated/graphql'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { ProviderParticipantDetailLearningNeedsDetailRouteParams, providerRoutes } from 'routes/provider/providerRoutes'
import { DateFormatters } from 'utils/formatters/Date/Date'
import { ProviderGroupFormFields } from '../Aanbieder/AanbiederGroups/ProviderGroupFormFields'
import { participationEndOptionsTranslations } from '../Groups/Translations/groupTranslations'
import { ProviderEmployeeFieldset } from '../Shared/Fieldsets/ProviderEmployeeFieldset'
import { ParticipantsLearningNeedReferenceTestFields } from '../Shared/LearningNeeds/ParticipantsLearningNeedReferenceTestFields'
import styles from './ParticipationReferenceCard.module.scss'

interface Props {
    learningNeed: LearningNeedQuery['learningNeed']
    participation: ParticipationsQuery['participations']['nodes'][0]
    languageHouseName: string
}

export const ParticipationReferenceCard: React.FunctionComponent<Props> = props => {
    const { participation, learningNeed } = props
    const { learningNeedId, providerParticipantId } =
        useParams<ProviderParticipantDetailLearningNeedsDetailRouteParams>()
    const history = useHistory()
    const { i18n } = useLingui()
    const contextUser = useContext(SessionContext).user
    const contextOrganization = contextUser?.person?.employee?.organization
    const isOwnReference = participationProviderEqualsContextOrganization()
    const isEditable = participationIsEditable()
    const referralPath = providerRoutes.participants
        .detail(providerParticipantId)
        .data.learningNeeds.detail(learningNeedId)
        .referrals.detail(participation.id)

    return (
        <ReferenceCard
            showEditButton={isEditable}
            isReadOnly={!isEditable && !isOwnReference}
            onClickEditTopComponent={() => {
                if (contextOrganization?.type === OrganizationTypeEnum.LanguageHouse) {
                    history.push(referralPath.update)
                } else if (contextOrganization?.type === OrganizationTypeEnum.Provider) {
                    if (participation.educationGroup && referralPath.groupAssignment?.update) {
                        history.push(referralPath.groupAssignment?.update)
                    }
                    if (participation.mentor && referralPath.mentorAssignment?.update) {
                        history.push(referralPath.mentorAssignment?.update)
                    }
                }
            }}
            TopComponent={
                <ReferenceCardLinkedHeader
                    StatusComponent={renderStatusInfo()}
                    InformationComponent={renderTopInfo()}
                    MoreInformationComponent={renderMoreInfo()}
                />
            }
            BottomComponent={renderBottomComponent()}
            onClickEditBottomComponent={
                participation.testResult?.id
                    ? () => history.push(referralPath.testResult.update(participation.testResult!.id))
                    : undefined
            }
        />
    )

    function renderBottomComponent() {
        if (!isOwnReference) {
            return
        }

        // own reference of provider
        if (contextUser?.accessGroup === OrganizationTypeEnum.Provider) {
            if (hasEducationGroupOrMentor()) {
                return renderTestInfo()
            }

            return renderLinkButtons()
        }

        // own reference of language house
        if (contextUser?.accessGroup === OrganizationTypeEnum.LanguageHouse) {
            return renderTestInfo()
        }
    }

    function renderStatusInfo() {
        if (!participation.providerOption) {
            return <></>
        }

        return (
            <OngoingStatus
                title={participation.offerName!}
                supplierName={participation.provider?.name || participation.providerOther || ''}
                languageHouseName={props.languageHouseName}
                providerOption={participation.providerOption}
                status={participation.status}
                isOwnReference={isOwnReference}
                hasEducationGroup={!!participation.educationGroup}
                hasMentor={!!participation.mentor}
            />
        )
    }

    function renderTopInfo() {
        return (
            <Column spacing={6}>
                <Column>
                    <Field label={i18n._('Startdatum activiteit')} horizontal={true}>
                        <Paragraph>{renderActivityDate(participation.start)}</Paragraph>
                    </Field>
                    <Field label={i18n._('Einddatum activiteit')} horizontal={true}>
                        <Paragraph>{renderActivityDate(participation.end)}</Paragraph>
                    </Field>
                </Column>
                <Column>
                    <Field label={renderStartDateLabel()} horizontal={true}>
                        <Paragraph>
                            {participation.startParticipation &&
                                DateFormatters.formattedDate(participation.startParticipation)}
                        </Paragraph>
                    </Field>
                    {participation.endParticipation && (
                        <Field label={renderEndDateLabel()} horizontal={true}>
                            <Paragraph>{DateFormatters.formattedDate(participation.endParticipation)}</Paragraph>
                        </Field>
                    )}
                    {participation.reasonEndParticipation && (
                        <Field label={i18n._('Reden gestopt')} horizontal={true}>
                            <Paragraph>
                                {participationEndOptionsTranslations[participation.reasonEndParticipation]}
                            </Paragraph>
                        </Field>
                    )}
                </Column>
            </Column>
        )
    }

    function renderActivityDate(date?: Maybe<string>) {
        if (participation.mentor) {
            // when activity type is mentor, there is no concept of start/end of the activity
            return i18n._('n.v.t.')
        }

        if (!date) {
            return '-'
        }

        return DateFormatters.formattedDate(date)
    }

    function renderStartDateLabel() {
        if (participation.start && new Date(participation.start) > new Date()) {
            return i18n._('Deelnemer begint per')
        }

        return i18n._('Deelnemer begonnen per')
    }

    function renderEndDateLabel() {
        if (participation.end && new Date(participation.end) > new Date()) {
            return i18n._('Deelnemer stopt per')
        }

        return i18n._('Deelnemer gestopt per')
    }

    function renderMoreInfo() {
        if (participation.providerOption === ParticipationProviderOption.Other) {
            return (
                <Column spacing={6}>
                    <OfferInformationFieldset defaultValues={participation} readOnly={true} />
                    <HorizontalRule dark={true} thin={true} />
                    <DetailsInformationFieldset
                        defaultValues={participation}
                        readOnly={true}
                        fieldControls={{ end: { hidden: true }, start: { hidden: true } }}
                    />
                </Column>
            )
        }

        if (participation.providerOption === ParticipationProviderOption.Provider) {
            if (participation.educationGroup) {
                return (
                    <ProviderGroupFormFields
                        prefillData={participation.educationGroup}
                        readOnly={true}
                        hideAvailabilityFieldLabel={true}
                    />
                )
            }

            if (participation.mentor) {
                return <ProviderEmployeeFieldset prefillData={participation.mentor} readOnly={true} />
            }
        }
    }

    function renderLinkButtons() {
        if (!referralPath.groupAssignment?.select) {
            return
        }

        return (
            <Section title={i18n._('Koppelen')} className={styles.addNewSection}>
                <Field>
                    <Row>
                        <Button
                            type={ButtonType.tertiary}
                            icon={IconType.addGroup}
                            onClick={() => history.push(referralPath.groupAssignment!.select)}
                        >
                            {i18n._('Toevoegen aan deelnamegroep')}
                        </Button>
                        <Button
                            type={ButtonType.tertiary}
                            icon={IconType.addGroup}
                            onClick={() => history.push(referralPath.mentorAssignment!.select)}
                        >
                            {i18n._('Koppelen aan begeleider')}
                        </Button>
                    </Row>
                </Field>
            </Section>
        )
    }

    function renderTestInfo() {
        if (!participation.testResult && isOwnReference) {
            return (
                <Section title={i18n._('Resultaat')} className={styles.addNewSection}>
                    <Field>
                        <Column>
                            <Button
                                type={ButtonType.tertiary}
                                onClick={() => history.push(referralPath.testResult.create)}
                            >
                                {i18n._('Activiteit afronden')}
                            </Button>
                        </Column>
                    </Field>
                </Section>
            )
        }

        if (!participation.testResult) {
            return undefined
        }

        return <ParticipantsLearningNeedReferenceTestFields participation={participation} readOnly={true} />
    }

    function participationProviderEqualsContextOrganization() {
        if (!contextOrganization) {
            return false
        }

        if (participation.providerOption === ParticipationProviderOption.Provider) {
            return participation.provider?.id === contextOrganization.id
        }

        if (participation.providerOption === ParticipationProviderOption.Other) {
            /**
             * This case means that the participation is provided
             * by the student's language house organization
             */
            return learningNeed.student.organization.id === contextOrganization.id
        }

        return false
    }

    function participationIsEditable() {
        if (contextUser?.accessGroup === OrganizationTypeEnum.LanguageHouse) {
            if (participation.providerOption === ParticipationProviderOption.Provider) {
                if (participation.status === ParticipationStatus.Referred) {
                    return true
                }
            }

            if (participation.providerOption === ParticipationProviderOption.Other) {
                return true
            }
        }

        if (contextUser?.accessGroup === OrganizationTypeEnum.Provider) {
            return isOwnReference && hasEducationGroupOrMentor()
        }

        return false
    }

    function hasEducationGroupOrMentor() {
        return !!(participation.educationGroup || participation.mentor)
    }
}
