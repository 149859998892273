import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Headline, { SpacingType } from 'components/Chrome/Headline'
import Actionbar from 'components/Core/Actionbar/Actionbar'
import Button, { ButtonType } from 'components/Core/Button/Button'
import Column from 'components/Core/Layout/Column/Column'
import Row from 'components/Core/Layout/Row/Row'
import Space from 'components/Core/Layout/Space/Space'
import { PageQuery } from 'components/Core/PageQuery/PageQueryGraphql'
import TaalhuisInformationFieldset from 'components/fieldsets/taalhuis/TaalhuisInformationFieldset'
import React, { useContext } from 'react'
import { taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'
import { useHistory } from 'react-router-dom'
import {
    TaalhuisManagementTab,
    TaalhuisManagementTabs,
} from 'components/Domain/Taalhuis/Management/Tabs/TaalhuisManagementTabs'
import { OrganizationQuery, useOrganizationQuery } from 'graphql/v2/generated/graphql'
import { SessionContext } from 'components/Providers/SessionProvider/context'

interface Props {}

export const ManagementTaalhuisDetailsDataView: React.FunctionComponent<Props> = props => {
    const { i18n } = useLingui()
    const userContext = useContext(SessionContext)
    const history = useHistory()
    const organizationId = userContext.user?.person?.employee?.organization.id!

    const { data, loading, error } = useOrganizationQuery({
        variables: {
            input: organizationId,
        },
    })

    return (
        <PageQuery loading={loading} error={error} data={data}>
            {renderPageContent}
        </PageQuery>
    )

    function renderPageContent(data: OrganizationQuery) {
        return (
            <>
                <Headline title={i18n._(t`Beheer`)} spacingType={SpacingType.small} />

                <Column spacing={10}>
                    <TaalhuisManagementTabs activeTabId={TaalhuisManagementTab.TaalhuisDetails} />
                    {renderView(data.organization)}
                </Column>
                <Space pushTop={true} />
                <Actionbar
                    RightComponent={
                        <Row>
                            <Button
                                type={ButtonType.primary}
                                onClick={() => history.push(taalhuisRoutes.management.taalhuisDetails.data.update)}
                            >
                                {i18n._(t`Bewerken`)}
                            </Button>
                        </Row>
                    }
                />
            </>
        )
    }

    function renderView(organization: OrganizationQuery['organization']) {
        const address = organization.address
        const telephone = organization.telephone
        const email = organization.email

        return (
            <TaalhuisInformationFieldset
                readOnly={true}
                prefillData={{
                    name: organization.name,
                    'address.street': address?.street,
                    'address.houseNumber': address?.houseNumber,
                    'address.houseNumberSuffix': address?.houseNumberSuffix,
                    'address.postalCode': address?.postalCode,
                    'address.locality': address?.locality,
                    telephone: telephone || undefined,
                    email: email || undefined,
                    postalCodes: organization.postalCodes?.map(c => c.code),
                }}
            />
        )
    }
}
