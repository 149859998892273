import { SessionProvider } from 'components/Providers/SessionProvider/SessionProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { I18nLoader } from './components/Providers/I18nLoader/I18nLoader'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'
import { env } from 'env'
import { CachePolicies } from 'configs/CachePolicies'
import { onError } from '@apollo/client/link/error'
import { authLink, handleUnauthenticatedGraphqlError } from 'auth'

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err.extensions.code) {
                case 'UNAUTHENTICATED':
                    return handleUnauthenticatedGraphqlError(err, operation, forward)
                default:
                    // eslint-disable-next-line no-console
                    console.log(`[GraphQL error]:`, err)
            }
        }
    }

    if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]:`, networkError)
    }
})

const httpLink = createHttpLink({
    uri: env.apiBasePath + '/graphql',
})

export const apolloClient = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({ typePolicies: CachePolicies }),
})

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <SessionProvider>
                <I18nLoader>
                    <Router>
                        <App />
                    </Router>
                </I18nLoader>
            </SessionProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
