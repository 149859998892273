import classNames from 'classnames'
import { SortInput } from 'graphql/v2/generated/graphql'
import { useEffect, useState } from 'react'
import { EmptyState } from '../EmptyState/EmptyState'
import Icon from '../Icon/Icon'
import { IconType } from '../Icon/IconType'
import styles from './Table.module.scss'

interface Props {
    headers: TableHeader[]
    rows: JSX.Element[][] | null
    flex: (number | string) | (number | string)[]
    lastItemIsIcon?: boolean
    emptyMessage: string
    onSortDirectionChange?: (sortDirection: SortDirection) => void
    sortDirection?: SortDirection
}

export interface TableHeader {
    alignment?: TableColumnAlignment
    field: string
    headerLabel: string
    sortable?: boolean
}

export enum TableColumnAlignment {
    Left,
    Right,
}

export interface SortDirection {
    field: string
    direction: SortInput
}

export const Table: React.FunctionComponent<Props> = props => {
    const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(props.sortDirection)

    useEffect(() => {
        setSortDirection(props.sortDirection)
    }, [props.sortDirection])

    return (
        <div>
            <table className={styles.tableContainer}>
                <thead className={styles.tableHeaderContainer}>
                    <tr className={styles.tableRow}>
                        {props.headers.map((h, i) => (
                            <th
                                key={i}
                                className={classNames(styles.title, {
                                    [styles.columnAlignmentRight]: h.alignment === TableColumnAlignment.Right,
                                })}
                                style={getFlexHeaderStyles(i)}
                                onClick={handleSortDirectionChange(h.field, h.sortable)}
                            >
                                {h.headerLabel}
                                {h.sortable && renderSortIcon(h.field)}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className={styles.containerBody}> {renderRows()}</tbody>
            </table>
        </div>
    )

    function renderRows() {
        if (!props.rows?.length) {
            return <EmptyState message={props.emptyMessage} />
        }

        return props.rows?.map((row, index) => (
            <tr className={styles.row} key={index}>
                {row.map((item, i) => (
                    <td
                        key={i}
                        style={getFlexRowStyles(i)}
                        className={classNames(styles.rowItem, {
                            [styles.columnAlignmentRight]: props.headers[i].alignment === TableColumnAlignment.Right,
                        })}
                    >
                        {item}
                    </td>
                ))}
            </tr>
        ))
    }

    function getFlexRowStyles(index: number) {
        const flexValues: any = {
            flex: Array.isArray(props.flex) ? props.flex[index] : props.flex,
            display: 'flex',
            alignItems: 'flex-start',
        }
        const isLastItem = index === props.headers.length - 1

        if (props.lastItemIsIcon && isLastItem) {
            flexValues.justifyContent = 'flex-end'
        }

        return flexValues
    }

    function getFlexHeaderStyles(index: number) {
        const flexValues: any = { flex: Array.isArray(props.flex) ? props.flex[index] : props.flex }
        const isLastItem = index === props.headers.length - 1

        if (props.lastItemIsIcon && isLastItem) {
            flexValues.justifyContent = 'flex-end'
        }

        return flexValues
    }

    function renderSortIcon(field: string) {
        const icon =
            sortDirection?.field === field && sortDirection.direction === SortInput.Asc
                ? IconType.fullArrowUp
                : IconType.fullArrowDown

        return (
            <div className={styles.sortIcon}>
                <Icon
                    type={icon}
                    className={
                        sortDirection?.field === field ? styles.sortIconStylesActive : styles.sortIconStylesInactive
                    }
                />
            </div>
        )
    }

    function handleSortDirectionChange(field: string, sortable: boolean = false) {
        if (!sortable) {
            return
        }

        return (e: React.MouseEvent) => {
            e.preventDefault()

            let direction: SortInput = SortInput.Asc
            if (sortDirection?.field === field) {
                direction = sortDirection.direction === SortInput.Asc ? SortInput.Desc : SortInput.Asc
            }

            setSortDirection({ field, direction })
            props.onSortDirectionChange?.({ field, direction })
        }
    }
}
