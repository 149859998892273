import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import {
    CivicIntegrationReason,
    CivicIntegrationRequirement,
    ContactPreference,
    EducationLevelEnum,
    Gender,
    IntakeParticipationLadder,
    MaritalStatus,
} from 'graphql/v2/generated/graphql'

export const civicIntegrationRequirementTranslations: { [key in CivicIntegrationRequirement]?: string } = {
    [CivicIntegrationRequirement.No]: i18n._(t`Nee`),
    [CivicIntegrationRequirement.Yes]: i18n._(t`Ja`),
    [CivicIntegrationRequirement.InProgress]: i18n._(t`Volgt momenteel inburgering`),
}

export const civicIntegrationRequirementReasonTranslations: {
    [key in CivicIntegrationReason]?: string
} = {
    [CivicIntegrationReason.ExemptedOrZRoute]: i18n._(t`Vanwege vrijstelling of Z-route`),
    [CivicIntegrationReason.FromEuCountry]: i18n._(t`Afkomstig uit EU land`),
    [CivicIntegrationReason.Finished]: i18n._(t`Afgerond`),
}

export const genderTranslations: { [key in Gender]?: string } = {
    [Gender.Male]: i18n._(t`Man`),
    [Gender.Female]: i18n._(t`Vrouw`),
    [Gender.X]: i18n._(t`X`),
}

export const contactPreferenceTranslations: { [key in ContactPreference]?: string } = {
    [ContactPreference.Phonecall]: i18n._(t`Bellen`),
    [ContactPreference.Whatsapp]: i18n._(t`Whatsapp`),
    [ContactPreference.Email]: i18n._(t`Mailen`),
    [ContactPreference.Other]: i18n._(t`Anders, namelijk...`),
}

export const maritalStatusTranslations: { [key in MaritalStatus]?: string } = {
    [MaritalStatus.Divorced]: i18n._(t`Gescheiden`),
    [MaritalStatus.MarriedPartner]: i18n._(t`Getrouwd/partner`),
    [MaritalStatus.Single]: i18n._(t`Alleenstaand`),
    [MaritalStatus.Widow]: i18n._(t`Weduwe/weduwnaar`),
}

export const participationLadderTranslations: { [key in IntakeParticipationLadder]?: string } = {
    [IntakeParticipationLadder.Isolated]: i18n._(t`1 geïsoleerd`),
    [IntakeParticipationLadder.SocialContactsOutside]: i18n._(t`2 sociale contacten buiten huis`),
    [IntakeParticipationLadder.OrganizedActivityParticipation]: i18n._(t`3 deelname georganiseerde activiteiten`),
    [IntakeParticipationLadder.VolunteerWork]: i18n._(t`4 vrijwilligers werk/maatschappelijke activering`),
    [IntakeParticipationLadder.PaidWithSupport]: i18n._(t`5 betaald werk met ondersteuning`),
    [IntakeParticipationLadder.Paid]: i18n._(t`6 betaald werk`),
}

export const educationLevelTranslations: { [key in EducationLevelEnum]?: string } = {
    [EducationLevelEnum.Primary]: i18n._(t`basisonderwijs`),
    [EducationLevelEnum.SpecialEd]: i18n._(t`Speciaal onderwijs (PO en/of VO)`),
    [EducationLevelEnum.DomesticSchool]: i18n._(t`Huishoudschool`),
    [EducationLevelEnum.BiologicSchool]: i18n._(t`Biologische school`),
    [EducationLevelEnum.Lts]: i18n._(t`LTS`),
    [EducationLevelEnum.Mavo]: i18n._(t`mavo`),
    [EducationLevelEnum.Vmbo]: i18n._(t`vmbo`),
    [EducationLevelEnum.Havo]: i18n._(t`havo`),
    [EducationLevelEnum.Vwo]: i18n._(t`vwo`),
    [EducationLevelEnum.Mbo]: i18n._(t`mbo`),
    [EducationLevelEnum.Hbo]: i18n._(t`hbo`),
    [EducationLevelEnum.Wo]: i18n._(t`wo`),
    [EducationLevelEnum.Other]: i18n._(t`Anders namelijk…`),
}
