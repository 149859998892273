import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Button from 'components/Core/Button/Button'
import ErrorBlock from 'components/Core/Feedback/Error/ErrorBlock'
import Spinner, { SpinnerAnimation } from 'components/Core/Feedback/Spinner/Spinner'
import { IconType } from 'components/Core/Icon/IconType'
import { DEFAULT_INITIAL_PAGE_SIZE, InfiniteScroll } from 'components/Core/InfiniteScroll/InfiniteScroll'
import Center from 'components/Core/Layout/Center/Center'
import Column from 'components/Core/Layout/Column/Column'
import Row from 'components/Core/Layout/Row/Row'
import { SortDirection, Table } from 'components/Core/Table/Table'
import { TableLink } from 'components/Core/Table/TableLink'
import Paragraph from 'components/Core/Typography/Paragraph'
import { ParticipationStatus, ProviderStudentsQuery, useProviderStudentsQuery } from 'graphql/v2/generated/graphql'
import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { providerRoutes } from 'routes/provider/providerRoutes'
import { DateFormatters } from 'utils/formatters/Date/Date'
import { NameFormatters } from 'utils/formatters/name/Name'
import { AanbiederParticipantsTabs } from './Tabs/AanbiederParticipantsTabs'

interface Props {
    participationStatus: ParticipationStatus
    onSortDirectionChange: (sortDirection: SortDirection) => void
    sortDirection?: SortDirection
}

export const ParticipantsTableContainer: React.FunctionComponent<Props> = props => {
    const { participationStatus, sortDirection, onSortDirectionChange } = props
    const { i18n } = useLingui()

    const referredCount = useProviderStudentsQuery({
        variables: { paginationArgs: { take: 1 }, participationStatus: ParticipationStatus.Referred },
    }).data?.providerStudents.totalCount

    const variables = useMemo(
        () => ({
            paginationArgs: { take: DEFAULT_INITIAL_PAGE_SIZE },
            participationStatus: participationStatus,
            sort: sortDirection ? { [sortDirection.field]: sortDirection.direction } : undefined,
        }),
        [participationStatus, sortDirection]
    )

    const query = useProviderStudentsQuery({ variables })
    const { data, loading, error, fetchMore, refetch } = query
    const history = useHistory()

    useEffect(() => {
        refetch(variables)
    }, [sortDirection, variables, refetch])

    return (
        <>
            <Column spacing={10}>
                <AanbiederParticipantsTabs referredCount={referredCount} currentTab={participationStatus} />
                <Row justifyContent="flex-end">
                    <Button icon={IconType.add} onClick={() => history.push(providerRoutes.participants.create)}>
                        {i18n._(`Nieuwe deelnemer`)}
                    </Button>
                </Row>
                <InfiniteScroll
                    hasMore={data?.providerStudents.hasMore ?? false}
                    loadMore={paginationArgs => fetchMore({ variables: { ...variables, paginationArgs } })}
                >
                    {renderList()}
                </InfiniteScroll>
            </Column>
        </>
    )

    function renderList() {
        if (!data && loading) {
            return (
                <Center grow={true}>
                    <Spinner type={SpinnerAnimation.pageSpinner} />
                </Center>
            )
        }

        if (error) {
            return (
                <ErrorBlock
                    title={i18n._(t`Er ging iets fout`)}
                    message={i18n._(t`Wij konden de gegevens niet ophalen, probeer het opnieuw`)}
                />
            )
        }

        return (
            <Table
                flex={1}
                headers={getHeaders()}
                rows={getRows(data?.providerStudents.nodes || [])}
                emptyMessage={i18n._(t`Er zijn nog geen deelnemers`)}
                onSortDirectionChange={onSortDirectionChange}
            />
        )
    }

    function getHeaders() {
        return [
            { headerLabel: i18n._(`ACHTERNAAM`), field: 'familyName', sortable: true },
            { headerLabel: i18n._(`ROEPNAAM`), field: 'givenName', sortable: true },
            { headerLabel: i18n._(`VERWEZEN DOOR`), field: 'referredBy' },
            { headerLabel: i18n._(`VERWEZEN PER`), field: 'referredAt' },
        ]
    }

    function getRows(nodes: ProviderStudentsQuery['providerStudents']['nodes']) {
        return nodes.map(n => [
            <TableLink
                text={NameFormatters.formattedLastName(n.person)}
                to={providerRoutes.participants.detail(n.id).index}
            />,
            <Paragraph>{n.person.givenName}</Paragraph>,
            <Paragraph>{n.organization?.name}</Paragraph>,
            <Paragraph>{DateFormatters.formattedDate(n.registration.createdAt)}</Paragraph>,
        ])
    }
}
