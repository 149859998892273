import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'
import { ParticipantsLearningNeedReadView } from './ParticipantsLearningNeedReadView'
import { ParticipantsLearningNeedUpdateView } from './ParticipantsLearningNeedUpdateView'

import { ParticipantsLearningNeedsReferencesView } from './References/ParticipantsLearningNeedsReferencesView'

export const ParticipantsLearningNeedsDetailView: React.FunctionComponent = () => {
    const basePath = taalhuisRoutes.participants.detail().data.learningNeeds

    return (
        <Switch>
            <Redirect path={basePath.index} exact={true} to={basePath.detail().index} />
            <Route path={basePath.detail().index} exact={true} component={ParticipantsLearningNeedReadView} />
            <Route path={basePath.detail().update} exact={true} component={ParticipantsLearningNeedUpdateView} />
            <Route path={basePath.detail().referrals.index} component={ParticipantsLearningNeedsReferencesView} />
        </Switch>
    )
}
