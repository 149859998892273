import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ErrorBlock from 'components/Core/Feedback/Error/ErrorBlock'
import Spinner, { SpinnerAnimation } from 'components/Core/Feedback/Spinner/Spinner'
import Center from 'components/Core/Layout/Center/Center'
import Column from 'components/Core/Layout/Column/Column'
import { TaalhuisParticipantDetailTabsEnum } from 'components/Domain/Taalhuis/Participants/TaalhuisParticipantDetailTabs'
import { useStudentForMentorQuery } from 'graphql/v2/generated/graphql'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { TaalhuisParticipantsDetailRouteParams, taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'
import { ParticipantDetailHeader } from '../ParticipantDetailHeader'
import { ParticipantMentorDetailView } from './ParticipantMentorDetailView'
import { ParticipantMentorUpdateView } from './ParticipantMentorUpdateView'

export const ParticipantMentorView: React.FunctionComponent = () => {
    const { i18n } = useLingui()
    const { taalhuisParticipantId } = useParams<TaalhuisParticipantsDetailRouteParams>()

    const { data, loading, error } = useStudentForMentorQuery({
        variables: {
            studentId: taalhuisParticipantId,
        },
    })

    return (
        <Column spacing={12}>
            <ParticipantDetailHeader activeTabId={TaalhuisParticipantDetailTabsEnum.Mentor} />
            {renderPage()}
        </Column>
    )

    function renderPage() {
        if (loading) {
            return (
                <Center grow={true}>
                    <Spinner type={SpinnerAnimation.pageSpinner} />
                </Center>
            )
        }

        if (error || !data?.student) {
            return (
                <ErrorBlock
                    title={i18n._(t`Er ging iets fout`)}
                    message={i18n._(t`Wij konden de gegevens niet ophalen, probeer het opnieuw`)}
                />
            )
        }

        const basePath = taalhuisRoutes.participants.detail().data.mentor

        return (
            <Switch>
                <Route path={basePath.detail} exact={true} component={ParticipantMentorDetailView} />
                <Route path={basePath.update} exact={true} component={ParticipantMentorUpdateView} />
                <Redirect path={basePath.index} to={basePath.detail} />
            </Switch>
        )
    }
}
