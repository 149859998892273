import { useApolloClient } from '@apollo/client'
import { LoginMutationVariables, useCurrentUserQuery, useLoginMutation } from 'graphql/v2/generated/graphql'
import { handleLogout } from 'auth'
import { FunctionComponent } from 'react'
import { accessTokenLocalstorageKey, refreshTokenLocalstorageKey } from './constants'
import { SessionContext } from './context'
import { permissions } from 'utils/helpers'

interface Props {}

export const SessionProvider: FunctionComponent<Props> = props => {
    const { children } = props

    const client = useApolloClient()
    const [login, loginMutation] = useLoginMutation()
    const { data: dontUseThisData, ...currentUserQuery } = useCurrentUserQuery()

    /**
     * Even though the currentUserQuery provides an expected error (e.g. after logout)
     * the 'data' is still containing the currentUser. So we want to explicitly check
     * for the presence of an error.
     */
    const currentUser = !currentUserQuery.error ? dontUseThisData?.currentUser : undefined

    return (
        <SessionContext.Provider
            value={{
                loginLoading: loginMutation.loading,
                loginError: loginMutation.error,
                login: handleLogin,
                userLoading: currentUserQuery.loading,
                user: currentUser,
                logout: handleLogout,
                permissions: permissions(currentUser?.accessGroup, currentUser?.person?.employee?.role),
            }}
        >
            {children}
        </SessionContext.Provider>
    )

    async function handleLogin(variables: LoginMutationVariables) {
        const response = await login({
            variables,
        })

        if (response.data?.login.accessToken && response?.data.login.refreshToken) {
            localStorage.setItem(accessTokenLocalstorageKey, response.data.login.accessToken)
            localStorage.setItem(refreshTokenLocalstorageKey, response.data.login.refreshToken)
        }

        await client.refetchQueries({
            include: ['currentUser'],
        })

        return response
    }
}
