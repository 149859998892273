import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Headline, { SpacingType } from 'components/Chrome/Headline'
import { breadcrumbItems } from 'components/Core/Breadcrumbs/breadcrumbItems'
import { Breadcrumbs } from 'components/Core/Breadcrumbs/Breadcrumbs'
import ErrorBlock from 'components/Core/Feedback/Error/ErrorBlock'
import Spinner, { SpinnerAnimation } from 'components/Core/Feedback/Spinner/Spinner'
import Center from 'components/Core/Layout/Center/Center'
import Column from 'components/Core/Layout/Column/Column'
import { TaalhuisManagementEmployeeTabs } from 'components/Domain/Taalhuis/Management/Tabs/TaalhuisManagementEmployeeTabs'
import { useEmployeeQuery } from 'graphql/v2/generated/graphql'
import React from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { TaalhuisManagementCoworkerDetailRouteParams, taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'
import { NameFormatters } from 'utils/formatters/name/Name'
import { ManagementTaalhuisEmployeeMenteesView } from './ManagementTaalhuisEmployeeMenteesView'
import { ManagementTaalhuisEmployeesDetailDataView } from './ManagementTaalhuisEmployeesDetailDataView'
import { ManagementTaalhuisEmployeesDetailUpdateView } from './ManagementTaalhuisEmployeesDetailUpdateView'

interface Props {}

export const ManagementTaalhuisEmployeesDetailView: React.FunctionComponent<Props> = () => {
    const { i18n } = useLingui()
    const { taalhuisEmployeeId } = useParams<TaalhuisManagementCoworkerDetailRouteParams>()

    const { data, loading, error } = useEmployeeQuery({
        variables: {
            id: taalhuisEmployeeId,
        },
    })

    if (!data && loading) {
        return (
            <Center grow={true}>
                <Spinner type={SpinnerAnimation.pageSpinner} />
            </Center>
        )
    }
    if (error) {
        return (
            <ErrorBlock
                title={i18n._(t`Er ging iets fout`)}
                message={i18n._(t`Wij konden de gegevens niet ophalen, probeer het opnieuw`)}
            />
        )
    }

    const employeeFullName = data?.employee.person ? NameFormatters.formattedFullname(data?.employee.person) : ''

    return (
        <Column spacing={12}>
            <div>
                <Headline
                    title={employeeFullName}
                    spacingType={SpacingType.small}
                    TopComponent={
                        <Breadcrumbs
                            breadcrumbItems={[
                                breadcrumbItems.taalhuis.management.overview,
                                breadcrumbItems.taalhuis.management.employees,
                            ]}
                        />
                    }
                />
                <TaalhuisManagementEmployeeTabs />
            </div>
            <Switch>
                <Route
                    path={taalhuisRoutes.management.coworkers.detail().data.index}
                    exact={true}
                    component={ManagementTaalhuisEmployeesDetailDataView}
                />
                <Route
                    path={taalhuisRoutes.management.coworkers.detail().data.update}
                    exact={true}
                    component={ManagementTaalhuisEmployeesDetailUpdateView}
                />
                <Route
                    path={taalhuisRoutes.management.coworkers.detail().mentees}
                    exact={true}
                    component={ManagementTaalhuisEmployeeMenteesView}
                />
                <Redirect
                    path={taalhuisRoutes.management.coworkers.detail().index}
                    to={taalhuisRoutes.management.coworkers.detail().data.index}
                />
            </Switch>
        </Column>
    )
}
