import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Button, { ButtonType } from 'components/Core/Button/Button'
import { NotificationsManager } from 'components/Core/Feedback/Notifications/NotificationsManager'
import Form from 'components/Core/Form/Form'
import Column from 'components/Core/Layout/Column/Column'
import ModalView from 'components/Core/Modal/ModalView'
import SectionTitle from 'components/Core/Text/SectionTitle'
import Paragraph from 'components/Core/Typography/Paragraph'
import { useState } from 'react'
import { downloadFile } from 'utils/downloadFile'
// import React, { useEffect } from 'react'
// import { downloadFile } from 'utils/downloadFile'
import { Forms } from 'utils/forms'
import { TaalhuisPeriodFieldset, TaalhuisPeriodFieldsetFormModel } from '../Fieldsets/TaalhuisPeriodFieldset'

interface Props {
    onClose: () => void
    organizationId?: string
}

interface FormModel extends TaalhuisPeriodFieldsetFormModel {}

export const DownloadParticipantsModalView: React.FunctionComponent<Props> = props => {
    const { i18n } = useLingui()
    const { onClose, organizationId } = props
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <Form onSubmit={handleDownload}>
            <ModalView
                onClose={onClose}
                ContentComponent={
                    <Column spacing={6}>
                        <SectionTitle title={i18n._(t`Gegevens deelnemers downloaden`)} heading="H4" />
                        <Paragraph>
                            {i18n._(t`
                                Download een CSV bestand van alle deelnemers van dit Taalhuis. Gefilterd op periode naar keuze.`)}
                        </Paragraph>

                        <TaalhuisPeriodFieldset showTaalhuisSelect={!organizationId} />
                        <br />
                        <br />
                        <br />
                        <br />
                        {/** TECH DEBT: making room for dropdown that would otherwise be cut off due to scrollable modal */}
                    </Column>
                }
                BottomComponent={
                    <>
                        <Button type={ButtonType.secondary} disabled={loading} onClick={onClose}>
                            {i18n._(t`Annuleren`)}
                        </Button>
                        <Button type={ButtonType.primary} loading={loading} submit={true}>
                            {i18n._(t`Gegevens downloaden`)}
                        </Button>
                    </>
                }
            />
        </Form>
    )

    async function handleDownload(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setLoading(true)
        const formData = Forms.getFormDataFromFormEvent<FormModel>(e)
        const organization = organizationId || formData.organization
        const start = formData.periodFrom
        const end = formData.periodTo

        if (organization && start && end) {
            try {
                await downloadFile('/exports/students', { organizationId: organization, start, end }, 'students.xlsx')

                NotificationsManager.success(i18n._(t`Rapportage wordt gedownload`))
                onClose()
            } catch (e) {
                NotificationsManager.error(i18n._(`Actie mislukt`), i18n._(`Er is een onverwachte fout opgetreden`))
                // eslint-disable-next-line no-console
                console.error(e)
            }
        } else {
            if (!organization) {
                NotificationsManager.error(i18n._(t`Actie mislukt`), i18n._(t`Selecteer een Taalhuis`))
            }

            if (!start || !end) {
                NotificationsManager.error(i18n._(t`Actie mislukt`), i18n._(t`Selecteer een periode`))
            }
        }

        setLoading(false)
    }
}
