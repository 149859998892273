import { EmployeeRole, OrganizationTypeEnum } from 'graphql/v2/generated/graphql'

export const permissions = (accessGroup?: OrganizationTypeEnum, employeeRole?: EmployeeRole | null) => {
    if (!accessGroup || !employeeRole) {
        return undefined
    }

    if (accessGroup === OrganizationTypeEnum.Provider) {
        return getProviderPermissions(employeeRole)
    }
}

function getProviderPermissions(employeeRole: EmployeeRole) {
    return {
        canAccessReferredParticipants: () => isProviderCoordiantor(employeeRole),
        canAccessExports: () => isProviderCoordiantor(employeeRole),
    }
}

function isProviderCoordiantor(employeeRole: EmployeeRole) {
    return [EmployeeRole.Coordinator, EmployeeRole.CoordinatorMentor].includes(employeeRole)
}
