import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import Tab from 'components/Core/TabSwitch/Tab'
import TabSwitch from 'components/Core/TabSwitch/TabSwitch'
import Row from 'components/Core/Layout/Row/Row'
import { ParticipationStatus } from 'graphql/v2/generated/graphql'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import { providerRoutes } from 'routes/provider/providerRoutes'

interface Props {
    currentTab: ParticipationStatus
    referredCount?: number | null
}

export const AanbiederParticipantsTabs: React.FunctionComponent<Props> = props => {
    const { permissions } = useContext(SessionContext)
    const history = useHistory()
    const { i18n } = useLingui()
    const { currentTab, referredCount } = props
    const canAccessReferredParticipants = permissions?.canAccessReferredParticipants()
    const overviewPath = providerRoutes.participants.overviews

    return (
        <Row justifyContent="flex-start">
            <TabSwitch activeTabId={overviewPath[currentTab]} onChange={props => history.push(props.tabid as string)}>
                <Tab label={i18n._(t`Actief`)} tabid={overviewPath.ongoing} />
                <Tab label={i18n._(t`Afgerond`)} tabid={overviewPath.finished} />
                {canAccessReferredParticipants && (
                    <Tab label={i18n._(t`Nieuw`)} indicatorCount={referredCount} tabid={overviewPath.referred} />
                )}
            </TabSwitch>
        </Row>
    )
}
