import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { providerRoutes } from 'routes/provider/providerRoutes'
import { ProviderManagementCoworkersView } from './Coworkers/ProviderManagementCoworkersView'
import { ProviderManagementDetailsEditView } from './ProviderManagementDetailsEditView'
import { ProviderManagementDetailsView } from './ProviderManagementDetailsView'

export const ManagementView: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route
                path={providerRoutes.management.supplierDetails.index}
                exact={true}
                component={ProviderManagementDetailsView}
            />
            <Route
                path={providerRoutes.management.supplierDetails.update}
                exact={true}
                component={ProviderManagementDetailsEditView}
            />
            <Route path={providerRoutes.management.coworkers.index} component={ProviderManagementCoworkersView} />
            <Redirect path={providerRoutes.management.index} to={providerRoutes.management.supplierDetails.index} />
        </Switch>
    )
}
