import { BiscSupplierFieldsetModel } from 'components/Domain/Bisc/Supplier/BiscSupplierFieldset'
import { OrganizationType, OrganizationTypeEnum } from 'graphql/v2/generated/graphql'

export function getMappedSupplierFormFields(formData: BiscSupplierFieldsetModel, defaultSupplier?: OrganizationType) {
    const address = {
        street: formData['address.street'] ?? '',
        houseNumber: formData['address.houseNumber'] ?? '',
        houseNumberSuffix: formData['address.houseNumberSuffix'] ?? '',
        postalCode: formData['address.postalCode'] ?? '',
        locality: formData['address.locality'] ?? '',
        country: 'NL',
        name: formData?.name ?? '',
    }

    return {
        type: OrganizationTypeEnum.Provider,
        // type: defaultSupplier ? defaultSupplier : formData.type,
        name: formData.name ?? '',
        address,
        telephone: formData?.telephone ?? '',
        email: formData?.email ?? '',
    }
}

export function getMappedUpdateSupplierFormFields(formData: BiscSupplierFieldsetModel) {
    const address = {
        street: formData['address.street'] ?? '',
        houseNumber: formData['address.houseNumber'] ?? '',
        houseNumberSuffix: formData['address.houseNumberSuffix'] ?? '',
        postalCode: formData['address.postalCode'] ?? '',
        locality: formData['address.locality'] ?? '',
        country: 'NL',
        name: formData.name ?? '',
    }

    return {
        name: formData.name ?? '',
        address,
        telephone: formData.telephone ?? '',
        email: formData.email ?? '',
    }
}
