import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { EmployeeRole, OrganizationTypeEnum, PersonType } from 'graphql/v2/generated/graphql'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'
import { NameFormatters } from 'utils/formatters/name/Name'
import { routes } from '../../routes/routes'
import { IconType } from '../Core/Icon/IconType'
import MainNavigation from '../Core/Navigation/MainNavigation/MainNavigation'
import MainNavigationEnvironmentCard from '../Core/Navigation/MainNavigation/MainNavigationEnvironmentCard'
import MainNavigationItem from '../Core/Navigation/MainNavigation/MainNavigationItem'
import AuthorizedContentLayout from '../Core/PageLayout/AuthorizedContentLayout'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import { providerRoutes } from 'routes/provider/providerRoutes'

interface Props {}

const AppChrome: React.FunctionComponent<Props> = props => {
    const { children } = props
    const { i18n } = useLingui()
    const { user, logout, permissions } = useContext(SessionContext)
    const location = useLocation()
    const history = useHistory()

    if (!user) {
        return null
    }

    const fullName = user.person
        ? NameFormatters.formattedFullname(user.person as Partial<PersonType>)
        : i18n._(t`Naam niet gevonden`)

    return (
        <AuthorizedContentLayout
            NavigationComponent={
                <MainNavigation
                    organizationType={user.accessGroup}
                    TopComponent={
                        <MainNavigationEnvironmentCard
                            name={i18n._(t`TOP`)}
                            environment={
                                user.accessGroup === OrganizationTypeEnum.Bisc
                                    ? i18n._(t`BiSC`)
                                    : user.person?.employee?.organization.name ?? ''
                            }
                            organizationType={user.accessGroup}
                        />
                    }
                    ListComponent={getNavigationByType()}
                    BottomComponent={
                        <>
                            <MainNavigationItem
                                label={fullName ?? ''}
                                icon={IconType.profile}
                                to={routes.authorized.profile.index}
                                active={
                                    isActive(routes.authorized.profile.index) ||
                                    isActive(routes.authorized.profile.update)
                                }
                                organizationType={user.accessGroup}
                            />
                            <MainNavigationItem
                                label={i18n._(t`Uitloggen`)}
                                icon={IconType.logOut}
                                onClick={() => {
                                    /**
                                     * The default app behaviour is to redirect the user back to the location he originated from,
                                     * right after successful login. But when the user manually logs out, we don't want this to happen.
                                     * The flag `userHasRequestedLogout: true` prevents this default behaviour.
                                     */
                                    history.replace(history.location, { userHasRequestedLogout: true })
                                    logout?.()
                                }}
                                organizationType={user.accessGroup}
                            />
                        </>
                    }
                />
            }
        >
            {children}
        </AuthorizedContentLayout>
    )

    function getNavigationByType() {
        if (!user) {
            return null
        }

        if (user.accessGroup === OrganizationTypeEnum.Bisc) {
            return renderBiscNavigation()
        }

        if (user.accessGroup === OrganizationTypeEnum.LanguageHouse) {
            return renderTaalhuisNavigation()
        }

        if (user.accessGroup === OrganizationTypeEnum.Provider) {
            return renderAanbiederNavigation()
        }

        return null
    }

    function renderBiscNavigation() {
        return (
            <>
                <MainNavigationItem
                    label={i18n._(t`Taalhuizen`)}
                    icon={IconType.taalhuis}
                    active={isActive(routes.authorized.bisc.taalhuizen.index)}
                    to={routes.authorized.bisc.taalhuizen.index}
                    organizationType={OrganizationTypeEnum.Bisc}
                />
                <MainNavigationItem
                    label={i18n._(t`Aanbieders`)}
                    icon={IconType.providers}
                    active={isActive(routes.authorized.bisc.suppliers.index)}
                    to={routes.authorized.bisc.suppliers.index}
                    organizationType={OrganizationTypeEnum.Bisc}
                />
                <MainNavigationItem
                    label={i18n._(t`Rapportages`)}
                    icon={IconType.rapportage}
                    active={isActive(routes.authorized.bisc.reports.index)}
                    to={routes.authorized.bisc.reports.index}
                    organizationType={OrganizationTypeEnum.Bisc}
                />
                <MainNavigationItem
                    label={i18n._(t`Beheer`)}
                    icon={IconType.settings}
                    active={isActive(routes.authorized.bisc.management.index)}
                    to={routes.authorized.bisc.management.index}
                    organizationType={OrganizationTypeEnum.Bisc}
                />

                {/* {renderDev()} */}
            </>
        )
    }

    function renderTaalhuisNavigation() {
        return (
            <>
                <MainNavigationItem
                    label={i18n._(t`Deelnemers`)}
                    icon={IconType.taalhuis}
                    active={isActive(taalhuisRoutes.participants.index)}
                    to={taalhuisRoutes.participants.index}
                    organizationType={OrganizationTypeEnum.LanguageHouse}
                />
                <MainNavigationItem
                    label={i18n._(t`Teams`)}
                    icon={IconType.profile}
                    active={isActive(taalhuisRoutes.teams.index)}
                    to={taalhuisRoutes.teams.index}
                    organizationType={OrganizationTypeEnum.LanguageHouse}
                />
                {user?.person?.employee?.role === EmployeeRole.Coordinator && (
                    <MainNavigationItem
                        label={i18n._(t`Rapportages`)}
                        icon={IconType.rapportage}
                        active={isActive(taalhuisRoutes.reports.index)}
                        to={taalhuisRoutes.reports.index}
                        organizationType={OrganizationTypeEnum.LanguageHouse}
                    />
                )}
                <MainNavigationItem
                    label={i18n._(t`Beheer`)}
                    icon={IconType.settings}
                    active={isActive(taalhuisRoutes.management.index)}
                    to={taalhuisRoutes.management.index}
                    organizationType={OrganizationTypeEnum.LanguageHouse}
                />
                {/* {renderDev()} */}
            </>
        )
    }

    function renderAanbiederNavigation() {
        return (
            <>
                <MainNavigationItem
                    label={i18n._(t`Deelnemers`)}
                    icon={IconType.taalhuis}
                    active={isActive(providerRoutes.participants.index)}
                    to={providerRoutes.participants.index}
                    organizationType={OrganizationTypeEnum.Provider}
                />
                <MainNavigationItem
                    label={i18n._(t`Groepen`)}
                    icon={IconType.group}
                    active={isActive(providerRoutes.groups.index)}
                    to={providerRoutes.groups.index}
                    organizationType={OrganizationTypeEnum.Provider}
                />
                {permissions?.canAccessExports() && (
                    <MainNavigationItem
                        label={i18n._(t`Rapportages`)}
                        icon={IconType.rapportage}
                        active={isActive(providerRoutes.reports.index)}
                        to={providerRoutes.reports.index}
                        organizationType={OrganizationTypeEnum.Provider}
                    />
                )}
                <MainNavigationItem
                    label={i18n._(t`Beheer`)}
                    icon={IconType.settings}
                    active={isActive(providerRoutes.management.index)}
                    to={providerRoutes.management.index}
                    organizationType={OrganizationTypeEnum.Provider}
                />
                {/* {renderDev()} */}
            </>
        )
    }

    // function renderDev() {
    //     return (
    //         process.env.NODE_ENV === 'development' &&
    //         user && (
    //             <>
    //                 <HorizontalRule />
    //                 <MainNavigationItem
    //                     label="Kitchensink"
    //                     icon={IconType.biscLogo}
    //                     active={location.pathname === routes.authorized.kitchensink}
    //                     to={routes.authorized.kitchensink}
    //                     organizationType={user.organization.type}
    //                 />
    //                 <MainNavigationItem
    //                     label="Lingui example"
    //                     icon={IconType.biscLogo}
    //                     active={location.pathname === routes.authorized.translationsExample}
    //                     to={routes.authorized.translationsExample}
    //                     organizationType={user.organization.type}
    //                 />
    //             </>
    //         )
    //     )
    // }

    function isActive(indexRoute: string) {
        return !!location.pathname.startsWith(indexRoute)
    }
}

export default AppChrome
