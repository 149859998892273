import { useLingui } from '@lingui/react'
import Headline, { SpacingType } from 'components/Chrome/Headline'
import Button, { ButtonType } from 'components/Core/Button/Button'
import { NotificationsManager } from 'components/Core/Feedback/Notifications/NotificationsManager'
import { IconType } from 'components/Core/Icon/IconType'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import ReportCard, { ReportCardBackgroundType } from 'components/Reports/ReportCard'
import ReportsList from 'components/Reports/ReportsList'
import { useContext, useState } from 'react'
import { downloadFile } from 'utils/downloadFile'

export const ReportsView: React.FunctionComponent = () => {
    const { i18n } = useLingui()
    const sessionContext = useContext(SessionContext)
    const [activitiesLoading, setActivitiesLoading] = useState<boolean>()

    return (
        <>
            <Headline spacingType={SpacingType.small} title={i18n._('Rapportages')} />
            <ReportsList>
                <ReportCard
                    backgroundType={ReportCardBackgroundType.blue}
                    title={i18n._('Activiteiten')}
                    description={i18n._('Download een CSV bestand van alle activiteiten van deze Aanbieder.')}
                    ActionButton={
                        <Button
                            icon={IconType.download}
                            type={ButtonType.quaternary}
                            loading={activitiesLoading}
                            onClick={() => downloadActivities()}
                        >
                            {i18n._('Activiteiten downloaden')}
                        </Button>
                    }
                />
            </ReportsList>
        </>
    )

    async function downloadActivities() {
        setActivitiesLoading(true)

        const organizationId = sessionContext.user?.person?.employee?.organization.id || ''
        try {
            await downloadFile('/exports/providerParticipations', { organizationId }, 'activities.xlsx')

            NotificationsManager.success(i18n._('Rapportage wordt gedownload'))
        } catch (e) {
            NotificationsManager.error(i18n._(`Actie mislukt`), i18n._(`Er is een onverwachte fout opgetreden`))
            // eslint-disable-next-line no-console
            console.error(e)
        }

        setActivitiesLoading(false)
    }
}
