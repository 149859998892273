import { FunctionComponent, useCallback, useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import AppChrome from 'components/Chrome/AppChrome'
import { routes } from 'routes/routes'
import { NotFoundView } from '../Generic/NotFoundView'
import { TaalhuisView } from './Taalhuis/TaalhuisView'
import { BiscView } from './Bisc/BiscView'
import { ProfileDataView } from './Profile/ProfileDataView'
import { ProfileUpdateView } from './Profile/ProfileUpdateView'
import { OrganizationTypeEnum } from 'graphql/v2/generated/graphql'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import { accessTokenLocalstorageKey, refreshTokenLocalstorageKey } from 'components/Providers/SessionProvider/constants'
import { ProviderView } from './Provider/ProviderView'

interface Props {}

export const AuthorizedView: FunctionComponent<Props> = () => {
    const sessionContext = useContext(SessionContext)
    const history = useHistory()
    const location = useLocation()

    /**
     * By default, we want to send the current history.location along
     * with the auto-logout redirect. This way, the user can be redirected
     * back to this location after successful login.
     *
     * However, on manual logout, we want to prevent this default behaviour.
     * The `userHasRequestedLogout` can tell us that this is a manual logout.
     * The flag must be set in the current history state, right before handling logout.
     */
    const getHistoryStateForLogoutRedirect = useCallback(() => {
        const userHasRequestedLogout = (location.state as any)?.userHasRequestedLogout || false

        if (userHasRequestedLogout) {
            return undefined
        }

        return { intendedLocation: history.location }
    }, [history, location.state])

    useEffect(() => {
        /**
         * If there is no user (after loading),
         * we want to redirect the user to the login page.
         */
        if (!sessionContext.user && !sessionContext.userLoading) {
            if (hasLoggedInBefore) {
                /**
                 * The hasLoggedInBefore flag tells us that the user has been
                 * automatically logged out (e.g. expired token).
                 * We want to show the 'logged-out' page.
                 */
                history.push(routes.unauthorized.loggedout, getHistoryStateForLogoutRedirect())
            } else {
                history.push(routes.unauthorized.login, getHistoryStateForLogoutRedirect())
            }
        }

        if (sessionContext.user) {
            hasLoggedInBefore = true
        }
    }, [sessionContext.user, sessionContext.userLoading, history, getHistoryStateForLogoutRedirect])

    if (!sessionContext.user) {
        return null
    }

    return (
        <AppChrome>
            <Switch>
                <Route path={routes.authorized.profile.index} exact={true} component={ProfileDataView} />
                <Route path={routes.authorized.profile.update} exact={true} component={ProfileUpdateView} />

                {sessionContext.user.accessGroup === OrganizationTypeEnum.Bisc && (
                    <Switch>
                        <Redirect path={routes.authorized.index} exact={true} to={routes.authorized.bisc.index} />
                        <Route path={routes.authorized.bisc.index} component={BiscView} />
                        <Route component={NotFoundView} />
                    </Switch>
                )}

                {sessionContext.user.accessGroup === OrganizationTypeEnum.LanguageHouse && (
                    <Switch>
                        <Redirect path={routes.authorized.index} exact={true} to={routes.authorized.taalhuis.index} />
                        <Route path={routes.authorized.taalhuis.index} component={TaalhuisView} />
                        <Route component={NotFoundView} />
                    </Switch>
                )}

                {sessionContext.user.accessGroup === OrganizationTypeEnum.Provider && (
                    <Switch>
                        <Redirect path={routes.authorized.index} exact={true} to={routes.authorized.supplier.index} />
                        <Route path={routes.authorized.supplier.index} component={ProviderView} />
                        <Route component={NotFoundView} />
                    </Switch>
                )}
            </Switch>
        </AppChrome>
    )
}

/**
 * This flag is set to false by default,
 * unless a (old) token is found in local storage.
 */
let hasLoggedInBefore: boolean =
    !!localStorage.getItem(accessTokenLocalstorageKey) || !!localStorage.getItem(refreshTokenLocalstorageKey)
