import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Headline from 'components/Chrome/Headline'
import Actionbar from 'components/Core/Actionbar/Actionbar'
import Button, { ButtonType } from 'components/Core/Button/Button'
import { NotificationsManager } from 'components/Core/Feedback/Notifications/NotificationsManager'
import Form from 'components/Core/Form/Form'
import { IconType } from 'components/Core/Icon/IconType'
import Row from 'components/Core/Layout/Row/Row'
import Modal from 'components/Core/Modal/Modal'
import { MutationErrorProvider } from 'components/Core/MutationErrorProvider/MutationErrorProvider'
import { PageQuery } from 'components/Core/PageQuery/PageQueryGraphql'
import TaalhuizenDetailBreadcrumbs from 'components/Domain/Bisc/Taalhuizen/Breadcrumbs/TaalhuizenDetailBreadcrumbs'
import { getMappedTaalhuisFormFields } from 'components/Domain/Taalhuis/mappers/taalhuisFieldsMappers'
import postalCodesSelectLoader from 'components/Domain/Taalhuis/postalCodesSelectLoader'
import TaalhuisInformationFieldset, {
    TaalhuisInformationFieldsetModel,
} from 'components/fieldsets/taalhuis/TaalhuisInformationFieldset'
import {
    OrganizationQuery,
    useAvailablePostalCodesQuery,
    useEditOrganizationMutation,
    useOrganizationQuery,
} from 'graphql/v2/generated/graphql'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BiscTaalhuizenDetailRouteParams } from 'routes/bisc/biscRoutes'
import { routes } from 'routes/routes'
import { Forms } from 'utils/forms'
import TaalhuisDeleteModalView from '../../Modals/TaalhuisDeleteModalView'

interface Props {}

export interface FormModel extends TaalhuisInformationFieldsetModel {}

export const TaalhuisDetailUpdateView: React.FunctionComponent<Props> = props => {
    const { languageHouseId } = useParams<BiscTaalhuizenDetailRouteParams>()
    const { data, error, loading } = useOrganizationQuery({
        variables: {
            input: languageHouseId,
        },
    })

    const { refetch } = useAvailablePostalCodesQuery({
        variables: {
            paginationArgs: {
                skip: 0,
                take: 20,
            },
        },
        fetchPolicy: 'no-cache',
    })
    const postalCodesLoad = postalCodesSelectLoader(refetch)
    const [editOrganizationMutation, { loading: editLoading, error: editError }] = useEditOrganizationMutation({
        update(cache) {
            cache.evict({ fieldName: 'organization' })
            cache.evict({ fieldName: 'organizations' })
        },
    })
    const { i18n } = useLingui()
    const history = useHistory()

    const [modalIsVisible, setModalIsVisible] = useState<boolean>(false)

    return (
        <PageQuery loading={loading} error={error} data={data}>
            {renderPageContent}
        </PageQuery>
    )

    function renderPageContent({ organization }: OrganizationQuery) {
        return (
            <Form onSubmit={handleEdit(organization)}>
                <Headline title={organization.name} TopComponent={<TaalhuizenDetailBreadcrumbs />} />
                {renderViews(organization)}
                <Actionbar
                    LeftComponent={
                        <Row>
                            <Button
                                type={ButtonType.secondary}
                                danger={true}
                                icon={IconType.delete}
                                onClick={() => setModalIsVisible(true)}
                            >
                                {i18n._(t`Taalhuis verwijderen`)}
                            </Button>
                        </Row>
                    }
                    RightComponent={
                        <Row>
                            <Button
                                type={ButtonType.secondary}
                                disabled={editLoading}
                                onClick={() =>
                                    history.push(routes.authorized.bisc.taalhuizen.detail(languageHouseId).data.index)
                                }
                            >
                                {i18n._(t`Annuleren`)}
                            </Button>

                            <Button loading={editLoading} type={ButtonType.primary} submit={true}>
                                {i18n._(t`Opslaan`)}
                            </Button>
                        </Row>
                    }
                />
            </Form>
        )
    }

    function handleEdit(organization: OrganizationQuery['organization']) {
        return async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            const formData = Forms.getFormDataFromFormEvent<FormModel>(e)
            const input = getMappedTaalhuisFormFields(formData)

            try {
                await editOrganizationMutation({
                    variables: {
                        input: { ...input, id: organization?.id },
                    },
                })
                NotificationsManager.success(i18n._(t`Taalhuis is bijgewerkt`))

                history.push(routes.authorized.bisc.taalhuizen.detail(languageHouseId).index)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                if (!error.data) {
                    NotificationsManager.error(
                        i18n._(t`Actie mislukt`),
                        i18n._(t`Er is een onverwachte fout opgetreden`)
                    )
                }
            }
        }
    }

    function renderViews(organization: OrganizationQuery['organization']) {
        const address = organization.address
        return (
            <>
                <MutationErrorProvider mutationError={editError?.message}>
                    <TaalhuisInformationFieldset
                        prefillData={{
                            name: organization.name,
                            'address.street': address?.street,
                            'address.houseNumber': address?.houseNumber,
                            'address.houseNumberSuffix': address?.houseNumberSuffix,
                            'address.postalCode': address?.postalCode,
                            'address.locality': address?.locality,
                            telephone: organization.telephone,
                            email: organization.email,
                            postalCodes: organization?.postalCodes?.map(c => c.code),
                        }}
                        postalCodesLoad={postalCodesLoad}
                    />
                </MutationErrorProvider>
                <Modal isOpen={modalIsVisible} onRequestClose={() => setModalIsVisible(false)}>
                    <TaalhuisDeleteModalView
                        onClose={() => setModalIsVisible(false)}
                        taalhuis={organization}
                        onSuccess={() => {
                            history.push(routes.authorized.bisc.taalhuizen.index)
                        }}
                    />
                </Modal>
            </>
        )
    }
}
