import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface Props {}

export const NotFoundView: React.FunctionComponent<Props> = () => {
    const { i18n } = useLingui()

    return <p>{i18n._(t`Deze pagina is niet gevonden. Gebruik het menu of de terugknop om verder te gaan.`)}</p>
}
