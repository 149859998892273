import React from 'react'
import { ApolloError } from '@apollo/client'
import { CurrentUserQuery, LoginMutationVariables } from 'graphql/v2/generated/graphql'
import { permissions } from 'utils/helpers'

export interface SessionContextValue {
    loginLoading: boolean
    loginError: ApolloError | undefined
    login?: (args: LoginMutationVariables) => Promise<unknown> | undefined
    userLoading: boolean
    user?: CurrentUserQuery['currentUser']
    logout?: () => void
    permissions: ReturnType<typeof permissions>
}

const DefaultContextValues = {
    loginLoading: false,
    loginError: undefined,
    login: () => undefined,
    userLoading: false,
    user: undefined,
    logout: () => undefined,
    permissions: permissions(),
}

export const SessionContext = React.createContext<SessionContextValue>(DefaultContextValues)
