import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { OrganizationIntakeFields } from 'graphql/v2/generated/graphql'
import { MandatoryIntakeSettings } from './intakeSettings'

export const intakeSettingsTranslations: { [key in OrganizationIntakeFields | MandatoryIntakeSettings]?: string } = {
    [OrganizationIntakeFields.IntegrationMandatory]: i18n._(t`Inburgeringsplichtig`),
    [MandatoryIntakeSettings.PersonalInfo]: i18n._(t`Persoonsgegevens`),
    [OrganizationIntakeFields.ContactData]: i18n._(t`Contactgegevens`),
    [OrganizationIntakeFields.General]: i18n._(t`Algemeen`),
    [OrganizationIntakeFields.Referer]: i18n._(t`Aanmelder`),
    [OrganizationIntakeFields.Background]: i18n._(t`Achtergrond`),
    [OrganizationIntakeFields.DutchNt]: i18n._(t`Nederlands NT`),
    [OrganizationIntakeFields.Level]: i18n._(t`Niveau`),
    [OrganizationIntakeFields.Education]: i18n._(t`Opleiding`),
    [OrganizationIntakeFields.Course]: i18n._(t`Cursus`),
    [OrganizationIntakeFields.Employment]: i18n._(t`Werk`),
    [OrganizationIntakeFields.Motivation]: i18n._(t`Motivatie`),
    [OrganizationIntakeFields.Availability]: i18n._(t`Beschikbaarheid`),
    [OrganizationIntakeFields.ReadingTest]: i18n._(t`Leestest`),
    [OrganizationIntakeFields.WritingTest]: i18n._(t`Schrijftest`),
    [MandatoryIntakeSettings.Permissions]: i18n._(t`Toestemmingen`),
}
