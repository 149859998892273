import React from 'react'
import { routes } from 'routes/routes'
import { useHistory } from 'react-router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import Tab from 'components/Core/TabSwitch/Tab'
import TabSwitch from 'components/Core/TabSwitch/TabSwitch'
import Row from 'components/Core/Layout/Row/Row'

interface Props {
    currentTab: BiscSupplierTab
    providerId: string
}

export enum BiscSupplierTab {
    data = 'data',
    employees = 'employees',
}

export const BiscSupplierTabs: React.FunctionComponent<Props> = props => {
    const history = useHistory()
    const { i18n } = useLingui()
    const { currentTab, providerId } = props

    return (
        <Row justifyContent="flex-start">
            <TabSwitch activeTabId={getRoute(currentTab)} onChange={props => history.push(props.tabid as string)}>
                <Tab label={i18n._(t`Gegevens`)} tabid={getRoute(BiscSupplierTab.data)} />
                <Tab label={i18n._(t`Medewerkers`)} tabid={getRoute(BiscSupplierTab.employees)} />
            </TabSwitch>
        </Row>
    )

    function getRoute(tab: BiscSupplierTab) {
        if (tab === BiscSupplierTab.data) {
            return routes.authorized.bisc.suppliers.detail(providerId).data.index
        }

        return routes.authorized.bisc.suppliers.detail(providerId).coworkers.index
    }
}
