import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { providerRoutes } from 'routes/provider/providerRoutes'
import { GroupsView } from './Groups/GroupsView'
import { ManagementView } from './Management/ManagementView'
import { ParticipantsView } from './Participants/ParticipantsView'
import { ReportsView } from './Reports/ReportsView'

export const ProviderView: React.FunctionComponent = () => {
    return (
        <Switch>
            <Redirect path={providerRoutes.index} exact={true} to={providerRoutes.participants.index} />
            <Route path={providerRoutes.participants.index} component={ParticipantsView} />
            <Route path={providerRoutes.groups.index} component={GroupsView} />
            <Route path={providerRoutes.reports.index} component={ReportsView} />
            <Route path={providerRoutes.management.index} component={ManagementView} />
        </Switch>
    )
}
