import { useState } from 'react'

interface Props<T> {
    children: (state: ParamStateOptions<T>) => JSX.Element
    // TODO: handle default state
    // defaultState: ParamState<T>
}

interface ParamStateOptions<T> {
    paramState: ParamState<T>
    setParamState: (state: ParamState<T>) => void
}

type ParamState<T> = {
    [K in keyof T]?: T[K]
}

export function ParamsManager<T>(props: Props<T>) {
    // TODO: set initial state from url params (with fallback to defaultState)
    const [paramState, setParamState] = useState<ParamState<T>>({})

    return props.children({ paramState, setParamState: setParamsStateAndUrlParams })

    function setParamsStateAndUrlParams(state: ParamState<T>) {
        setParamState(state)
        // TODO: replace current path state
    }
}
