import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import {
    EducationGroupTypeEnum,
    GroupFormality,
    GroupOfferType,
    ParticipationEndReason,
    ParticipationGroupType,
} from 'graphql/v2/generated/graphql'

export const groupCourseTypeTranslations: { [key in GroupOfferType]?: string } = {
    [GroupOfferType.Digital]: i18n._(t`Digitale vaardigheden`),
    [GroupOfferType.Language]: i18n._(t`Taal`),
    [GroupOfferType.Math]: i18n._(t`Rekenen`),
    [GroupOfferType.Other]: i18n._(t`Overige`),
}

export const groupFormationTypeTranslations: { [key in EducationGroupTypeEnum]?: string } = {
    [EducationGroupTypeEnum.Group]: i18n._(t`In een groep`),
    [EducationGroupTypeEnum.Individual]: i18n._(t`Individueel`),
}

export const groupFormalityTranslations: Record<GroupFormality, string> = {
    [GroupFormality.Formal]: i18n._(t`Formeel`),
    [GroupFormality.NonFormal]: i18n._(t`Non-formeel`),
}

export const participationGroupFormationTypeTranslations: { [key in ParticipationGroupType]?: string } = {
    [ParticipationGroupType.Group]: i18n._(t`In een groep`),
    [ParticipationGroupType.Individually]: i18n._(t`Individueel`),
}

export const participationEndOptionsTranslations: Record<ParticipationEndReason, string> = {
    [ParticipationEndReason.Moved]: i18n._('Verhuisd'),
    [ParticipationEndReason.Work]: i18n._('Werk'),
    [ParticipationEndReason.Health]: i18n._('Ziekte/gezondheid'),
    [ParticipationEndReason.Deceased]: i18n._('Overlijden'),
    [ParticipationEndReason.CompletedSuccessfully]: i18n._('Succesvol afgerond'),
    [ParticipationEndReason.Family]: i18n._('Familie omstandigheden'),
    [ParticipationEndReason.DoesNotMeetExpectations]: i18n._('Voldoet niet aan verwachting deelnemer'),
    [ParticipationEndReason.Other]: i18n._('Overig'),
}
